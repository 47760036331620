import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';

import TestAnimation from './pages/TestAnimation/TestAnimation';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import DataContext from './context/DataContext';
import Loading from './components/loading/Loading';
import ImageGallery from './pages/ImageGallery';

const ContactUs = lazy(()=> import('./pages/ContactUs'))
const Services = lazy(()=> import('./pages/Services'))
const NotFound = lazy(()=> import('./pages/NotFound'))
const Portfolio = lazy(()=> import('./pages/portfolio/Portfolio'))
const Pricing = lazy(()=> import('./pages/pricing/Pricing'))

function App() {
  
  const { availableHeight } = useContext(DataContext)

  const [loadOtherPages, setLoadOtherPages] = useState(false);

  useEffect(() => {
    if (!loadOtherPages) {
      const timeoutId = setTimeout(() => {
        setLoadOtherPages(true);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [loadOtherPages]);
  
  return (
    <div className="App">
        <Suspense fallback={<div className={`relative`} style={{height:`${availableHeight}px`}}><Loading /></div>}>
            <Routes>
                <Route path='' element={<Home />} />
                <Route path='/services' element={<Services />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/test-animation' element={<TestAnimation />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/image-gallery' element={<ImageGallery />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Suspense>
    </div>
  );
}

export default App;
