import '../../loader.css'

const Loading = () => {
    return (
        <div class="loader">
            <div class="segment-holder">
                <div class="segment one"></div>
            </div>
            <div class="segment-holder">
                <div class="segment two"></div>
            </div>
            <div class="segment-holder">
                <div class="segment three"></div>
            </div>
            <div class="segment-holder">
                <div class="segment four"></div>
            </div>
        </div>
    );
};

export default Loading;
