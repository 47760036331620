import React, { useRef, useEffect, useContext, useState } from 'react';
import Masonry from 'react-masonry-css';
import DataContext from '../context/DataContext';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';

const ImageGallery = () => {

    let { offsetY, setCurrentPage } = useContext(DataContext)

    useEffect(()=>{
        setCurrentPage('gallery')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    // 0780649379
    
    const images = [
        // "https://via.placeholder.com/400x500/FFC300/FFFFFF?text=Image+2",
        // "https://via.placeholder.com/400x4500/C70039/FFFFFF?text=Image+3",
        // "https://via.placeholder.com/400x500/900C3F/FFFFFF?text=Image+4",
        // "https://via.placeholder.com/400x650/581845/FFFFFF?text=Image+5",
        // "https://via.placeholder.com/400x49/17202A/FFFFFF?text=Image+6",
        "/gallery/Frame 1533.png",
        "/gallery/Frame 1524.png",
        "/gallery/Frame 1534.png",
        
        "/gallery/Frame 1529.png",
        "/gallery/Frame 1541.png",
        "/gallery/Frame 1532.png",
        // "https://via.placeholder.com/400x49/17202A/FFFFFF",
        "/gallery/Frame 1535.png",
        "/gallery/new 1/Frame 1532.png",
        "/gallery/new 1/Frame 1539.png",
        "/gallery/new 1/Frame 1540.png",
        "/gallery/new 1/Frame 1541.png",
        "/gallery/new 1/Frame 1543.png",

    ];


    const images2 = [
        "/gallery/Frame 1537.png",
        "/gallery/new 2/Frame 1544.png",
        "/gallery/new 2/Frame 1545.png",
        "/gallery/new 2/Frame 1547.png",
        "/gallery/Frame 1538.png",
        "/gallery/new 2/Frame 1553.png",
        "/gallery/Frame 1539.png",
        "/gallery/new 2/Frame 1552.png",
        "/gallery/Frame 1540.png",
        // "/gallery/Frame 1541.png",
        "/gallery/new 2/Frame 1539.png",
        "/gallery/new 2/Frame 1546-1.png",
        "/gallery/new 2/Frame 1546.png",
        "/gallery/new 2/Frame 1548.png",
        // "/gallery/new 2/Frame 1539.png",
        // "/gallery/new 2/Frame 1540.png",
        // "/gallery/new 2/Frame 1541.png",
        // "/gallery/new 2/Frame 1518.png"
    ]
    const illutration = "/gallery/Group 1518.png"

    const containerRef = useRef(null);
    const { ref, inView } = useInView()


    useEffect(() => {
        const handleScroll = () => {
            // const scrollTop = window.scrollY || document.documentElement.scrollTop;
            console.log("Hello");
        
            const columns = document.getElementsByClassName('my-masonry-grid_column');
        
            Array.from(columns).forEach((column, index) => {
                console.log(index)
                // column.style.background = 'red'
                column.style.transform = offsetY >= 80 ? index===0?`translateY(-${offsetY*0.1}px`:index===1?`translateY(-${offsetY*0.3}px`:`translateY(-${offsetY*0.5}px` : 'translateY(0)'
                console.log("offsety",offsetY)
            });
            const columns1 = document.getElementsByClassName('my-masonry-grid_column1');
        
            Array.from(columns1).forEach((column, index) => {
                // if(inView){
                    console.log(index)
                    // column.style.background = 'red'
                    column.style.transform = offsetY >= 80 ? index===0?`translateY(-${offsetY*0.05}px`:index===1?`translateY(${offsetY*0}px`:`translateY(${offsetY*0.05}px` : 'translateY(0)'
                    console.log("offsety",offsetY)
                // }
            });
        };
        

        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offsetY]); // Add containerRef as a dependency
    // const [ set]
    const [activeImage, setActiveImage] = useState(null)
    const [activeIndex, setActiveIndex] = useState(null)

    useEffect(()=>{
        if (activeImage===null) {
            console.log(`There's no active image: ${activeImage}`)
            return;
        } else {
            console.log(`Active image: ${activeImage}`)
            const handleKeyDown = (event) => {
                if (event.key === 'ArrowLeft') {
                    // Left arrow key is pressed
                    activeImage && console.log("Left arrow key is clicked");
                    setActiveImage(images[activeIndex-1])
                    setActiveIndex(activeIndex-1)
                    // Add your desired functionality here
                } else if (event.key === 'ArrowRight') {
                    // Right arrow key is pressed
                    activeImage && console.log(activeImage, 'Right arrow key is clicked');
                    setActiveImage(images[activeIndex+1])
                    setActiveIndex(activeIndex+1)
                    // Add your desired functionality here
                }
            };
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeImage]);


    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        400: 1
    };

    const [visible, setVisible] = useState(false);
    if (inView && !visible) {
        setVisible(true);
        // alert('Element is in viewport');
    } else if (!inView && visible) {
        setVisible(false);
        // alert('Element left viewport');
    }

    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollTimer;
    
        const handleScroll = () => {
            setIsScrolling(true);

            // Clear any existing timeout to restart the timer
            clearTimeout(scrollTimer);

            // Set a timeout to detect when scrolling stops
            scrollTimer = setTimeout(() => {
            setIsScrolling(false);
            }, 150); // Adjust the delay as needed
        };

        // Add scroll event listener when component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove the scroll event listener when component unmounts
        return () => {
            clearTimeout(scrollTimer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const animClasses = isScrolling ? [] : ['animated-bg', 'animated-bg-1', 'animated-bg-2','animated-bg', 'animated-bg-1', 'animated-bg-2','animated-bg', 'animated-bg-1', 'animated-bg-2'];
    const getRandomClass = () => {
        const randomIndex = Math.floor(Math.random() * animClasses.length);
        return animClasses[randomIndex];
    };
    

    return (
        <div className='overflow-x-hidden overflow-y-hidden'>
            <div>
                {
                    activeImage &&
                    <img src={activeImage} alt=""
                        style={{
                            position:"fixed",
                            top:'50%',
                            left:'50%',
                            transform: "translate(-50%,-50%)",
                            zIndex:"100"
                        }}
                        onLoad={()=>{}}
                    />
                }
            </div>
            <KeyboardArrowRight 
                className={`fixed top-[50%] -translate-y-1/2 scale-[3] hover:scale-[3.5] p-1 bg-white bg-opacity-20 hover:bg-opacity-30 z-[101] cursor-pointer rounded-3xl`}
                style={{
                    right: activeImage ? '28px' : '-100px'
                }}
                onClick={()=>{
                    setActiveImage(images[activeIndex+1])
                    setActiveIndex(activeIndex+1)
                }}
            />
            <KeyboardArrowLeft 
                className={`fixed top-[50%] -translate-y-1/2 scale-[3] hover:scale-[3.5] p-1 bg-white bg-opacity-20 hover:bg-opacity-30 z-[101] cursor-pointer rounded-3xl`}
                style={{
                    left: activeImage ? '24px' : '-100px'
                }}
                onClick={()=>{
                    setActiveImage(images[activeIndex-1])
                    setActiveIndex(activeIndex-1)
                }}
            />
            <div className='fixed w-full h-full bg-black bg-opacity-80 z-50 -bottom-[100%] cursor-pointer'
                title='Click to close'
                style={{
                    bottom:activeImage?'0':'100%'
                }}
                onClick={()=>{
                    setActiveImage(null)
                }}
            >
            </div>
            <section className="text-center w-full my-3">
                <h2 className=" text-xs font-medium text-gray-300">MASPEQA STUDIO</h2>
                <h1 className="text-4xl font-bold">PROJECTS GALLERY</h1>
            </section>
            <div className="container mx-auto mt-10 -z-50" ref={containerRef}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {
                        images.map((image, index) => (
                            <div key={index} className="relative rounded-md shadow-md scroll-1">
                                <img
                                    src={image}
                                    alt={`${index}`}
                                    className={`h-auto cursor-pointer hover:scale-105 ${isScrolling?'':Math.random() < 0.5 ? getRandomClass() : ''}`}
                                    // style={{
                                    //     position: activeImage===image ?'fixed':'',
                                    //     top:'50%',
                                    //     left:'50%',
                                    //     transform: activeImage===image?'translate(-50%,-50%)':'',
                                    //     width: activeImage===image?'400px':'full',
                                    //     zIndex: activeImage===image?'50':''
                                    // }}
                                    onClick={()=>{
                                        setActiveImage(image)
                                        setActiveIndex(index)
                                    }}
                                />
                            </div>
                        ))
                    }
                </Masonry>
            </div>
            <div className={`mt-20 mx-auto`} style={{transform:``}} ref={ref}>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column my-masonry-grid_column1"
                >
                    {
                        images2.map((image, index) => (
                            <div key={index} className="relative rounded-md shadow-md">
                                <img
                                    src={image}
                                    alt={`${index}`}
                                    className={`h-auto hover:scale-105 ${isScrolling?'':Math.random() < 0.4 ? getRandomClass() : ''}`}
                                    // style={{
                                    //     position: activeImage===image ?'fixed':'',
                                    //     top:'50%',
                                    //     left:'50%',
                                    //     transform: activeImage===image?'translate(-50%,-50%)':'',
                                    //     width: activeImage===image?'400px':'full',
                                    //     zIndex: activeImage===image?'50':''
                                    // }}
                                    // onClick={()=>{
                                    //     setActiveImage(image)
                                    //     setActiveIndex(index)
                                    // }}
                                />
                            </div>
                        ))
                    }
                </Masonry>
            </div>
            <img src={illutration} alt="" className={`w-full my-3`} />
        </div>
    );
};

export default ImageGallery;
