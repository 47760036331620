import { useState, useEffect, useRef, useContext } from "react";
import { motion } from "framer-motion";
import ListElement from "./listElement/ListElement";
import GlowingLink from "../global/GlowingLink";
import ToggleButton from "./toggleButton/toggle";
import DataContext from "../../context/DataContext";
import { Link } from "react-router-dom";
import CalendryWidget from "../CalendryWidget";



const Nav = () => {

    let { navHeight, setNavHeight, currentPage, isMdScreen } = useContext(DataContext)
    
    const [open, setOpen] = useState(false);
    const navRef = useRef()

    // GETTING THE HEIGHT OF NAVBAR
    useEffect(() => {
        const updateNavHeight = () => {
            if (navRef.current) {
                setNavHeight(navRef.current.offsetHeight);
            }
        };
        updateNavHeight();
        const handleResize = () => {
            updateNavHeight();
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    const [scrollFromNav, setScrollFromNav] = useState(false);

    const handleScroll = () => {
        const scrolledHeight = window.scrollY;
        const isScrolled = scrolledHeight > navHeight;
        setScrollFromNav(isScrolled);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navHeight]);

    useEffect(()=>{
        setOpen(false)
    },[isMdScreen])




    

    return (
        <nav>
            <CalendryWidget />
            <div
                ref={navRef}
                className={`z-30 fixed mt-[1px] py-2 2xl:w-[1540px] 2xl:ml-[50%] 2xl:-translate-x-1/2 ${scrollFromNav?'w-[96%] bg-white bg-opacity-5 backdrop-blur-md mx-[2%] top-2 rounded-full':'w-full top-0'}`}
            >
                <motion.div className="flex justify-between md:px-[2.5%] lg:px-[5.5%]">
                    <div className="p-2">
                        <Link to="/" className="font-bold" onClick={()=>{
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }}>
                            MASPEQA
                        </Link>
                    </div>
                    {
                        isMdScreen &&
                            <ul className="fixed md:static flex flex-col md:flex-row justify-between bg-white md:bg-transparent text-white list-none cursor-pointer m-0 p-0 w-[70%] md:w-8/12 lg:w-[55%] top-0 left-0">
                                <ListElement to="/" text="Home" active={isMdScreen&&(currentPage==='home'&&true)}/>
                                <ListElement to="/services" text="Services" active={isMdScreen&&(currentPage==='services'&&true)}/>
                                <ListElement to="/portfolio" text="Portfolio" active={isMdScreen&&(currentPage==='portfolio'&&true)}/>
                                <ListElement to="/image-gallery" text="Gallery" active={isMdScreen&&(currentPage==='gallery'&&true)}/>
                                <ListElement to="/pricing" text="Pricing" active={isMdScreen&&(currentPage==='pricing'&&true)}/>
                                <GlowingLink to='/contact-us' styles='text-white top-[50%] -translate-y-1/2 h-7' text='Talk to sales'/>
                            </ul>
                    }
                    <ToggleButton setOpen={setOpen} open={open} scrollFromNav={scrollFromNav}/>
                </motion.div>
            </div>
            {
                !isMdScreen &&
                    <div className="z-50 flex justify-between md:px-[2.5%] lg:px-[5%]">
                        <ul initial={false} className={` ${open?'ml-0':'ml-[-100%]'} border-r border-r-accent-2 border-opacity-40 z-[100] fixed md:static flex flex-col md:flex-row justify-start bg-black text-white md:bg-transparent list-none cursor-pointer m-0 p-0 w-[70%] md:w-8/12 lg:w-[55%] top-0 left-0 bottom-0`}>
                            <ListElement to='/' text='Home' setOpen={setOpen}/>
                            <ListElement to='/services' text='Services' setOpen={setOpen}/>
                            <ListElement to='/portfolio' text='Portfolio' setOpen={setOpen}/>
                            <ListElement to='/image-gallery' text='Gallery' setOpen={setOpen}/>
                            <ListElement to='/pricing' text='Pricing' setOpen={setOpen}/>
                            <ListElement to='/contact-us' text='Talk to sales' setOpen={setOpen}/>
                        </ul>
                    </div>
            }
        </nav>
    );
};

export default Nav;
