import { ArrowRightAlt } from "@mui/icons-material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import DataContext from "../../context/DataContext";

const GlowingLink = ({to, text, styles, setOpen}) => {


    const { currentPage } = useContext(DataContext)
    
    
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setOpen && setOpen(false)
    }
    
    return ( 
        <Link to={to} onClick={scrollToTop} className={`px-2 text-center block relative rounded-3xl border glowing-link border-white group ${styles&&styles} `}>
                {text}
                <span className="h-0 w-[80%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 glowing-white" style={{boxShadow:currentPage==='contact-us'?'0px 0px 25px 10px rgb(0, 247, 255)':'0px 0px 25px 10px rgba(255, 255, 255, 0.669)'}}></span>
                <ArrowRightAlt className="glowing-link-arrow"/>
        </Link>
    );
}

export default GlowingLink;