import FooterTitle from "./FooterTitle";
import FooterLink from "./FooterLink";
import FooterSection from "./FooterSection";
import { Facebook, Instagram, Language, LinkedIn, Phone, Twitter, WhatsApp } from "@mui/icons-material";
import { useContext } from "react";
import DataContext from "../../context/DataContext";

const Footer = () => {

    const { phoneNumber } = useContext(DataContext)
    
    return ( 
        <footer className={`px-[10%] md:px-[2.5%] lg:px-[5%] mt-20 shadow-inner shadow-accent-2 py-5 z-50 bg-black`}>
            <div className={`md:flex md:justify-between`}>
                <div className=" min-w-[90px] text-center">
                    <h1>MASPEQA</h1>
                    <img src={`/logo512.png`} alt="" className={`w-full max-w-[100px]`}/>
                </div>
                <FooterSection>
                    <FooterTitle text='Services' />
                    <FooterLink to='#' text='Software Development' />
                    <FooterLink to='#' text='Web Design' />
                    <FooterLink to='#' text='Graphic Design' />
                    <FooterLink to='#' text='System Analysis' />
                    <FooterLink to='#' text='Project Management' />
                </FooterSection>
                <FooterSection>
                    <FooterTitle text='Support' />
                    {/* <FooterLink to='#' text='FAQ' /> */}
                    <FooterLink to='/contact-us' text='Contact Us' />
                </FooterSection>
                <FooterSection>
                    <FooterTitle text='Maspeqa' />
                    <FooterLink to='/portfolio' text='Portfolio' />
                    <FooterLink to='#' text='Our Team' />
                </FooterSection>
                <div>
                    <FooterSection>
                        <FooterTitle text='Follow Us' />
                        <a className={`my-1 py-1 block text-gray-400 hover:text-white`} href="https://www.instagram.com/maspeqa" target="_blank" rel="noreferrer">Instagram</a>
                        <a className={`my-1 py-1 block text-gray-400 hover:text-white`} href="https://www.twitter.com/maspeqa" target="_blank" rel="noreferrer">Twitter</a>
                    </FooterSection>
                    <br />
                    <FooterSection>
                        <FooterTitle text='Talk to Us' />
                        <p>
                            <a className={`my-1 py-1 block text-gray-400 hover:text-white`} href={`tel:${phoneNumber}`} target="_blank" rel="noreferrer"><Phone />{phoneNumber}</a>
                        </p>
                        <p>
                            <a className={`my-1 py-1 block text-gray-400 hover:text-white`} href={`tel:${phoneNumber}`} target="_blank" rel="noreferrer"><WhatsApp />{phoneNumber}</a>
                        </p>
                    </FooterSection>
                </div>
            </div>
            <hr className="w-full border-gray-600 border-[1px] rounded-md ml-[50%] -translate-x-1/2 my-5"/>
            <div className={`text-center sm:text-justify sm:flex justify-between`}>
                <h1 className="mb-3 sm:mb-0">Maspeqa Ltd .2024&copy;</h1>
                <div className="mb-3 sm:mb-0">
                    <a className={`hover:text-gray-300 mx-2`} href="https://www.linkedin.com/maspeqa" target="_blank" rel="noreferrer"><LinkedIn /></a>
                    <a className={`hover:text-gray-300 mx-2`} href="https://www.twitter.com/maspeqa" target="_blank" rel="noreferrer"><Twitter /></a>
                    <a className={`hover:text-gray-300 mx-2`} href="https://www.facebook.com/maspeqa" target="_blank" rel="noreferrer"><Facebook /></a>
                    <a className={`hover:text-gray-300 mx-2`} href="https://www.instagram.com/maspeqa" target="_blank" rel="noreferrer"><Instagram /></a>
                </div>
                <div className={`text-gray-300 hover:text-white`}>
                    <Language /> English
                </div>
            </div>
        </footer>
     );
}
 
export default Footer;